import React from 'react';
import Layout from '../components/Layout';
import StyledHero from '../components/StyledHero';
import OverviewList from '../components/Financial/overviewList';
import Seo from '../components/Seo';
import { graphql } from 'gatsby';

const Finance = ({ data: { img } }) => {
	return (
		<Layout>
			<Seo title='Financieel' />
			<StyledHero img={img.childImageSharp.fluid} />
			<OverviewList />
		</Layout>
	);
};

export const query = graphql`
	query {
		img: file(relativePath: { eq: "financeBcg.jpeg" }) {
			childImageSharp {
				fluid(quality: 90, maxWidth: 4160) {
					...GatsbyImageSharpFluid_withWebp_tracedSVG
				}
			}
		}
	}
`;
export default Finance;
