import React from 'react';
import OverviewCard from './overviewCard';
import Title from '../Title';
import { useStaticQuery, graphql } from 'gatsby';
import styles from '../../css/financial.module.css';

const getOverview = graphql`
	query {
		overview: allContentfulFinancieel(sort: { fields: datum, order: DESC }) {
			edges {
				node {
					id: contentful_id
					titel
					slug
					datum(formatString: "DD-MM-YYYY")
					afbeelding {
						fluid {
							...GatsbyContentfulFluid
						}
					}
				}
			}
		}
	}
`;
const OverviewList = () => {
	const { overview } = useStaticQuery(getOverview);
	return (
		<section className={styles.financial}>
			<Title title='Financieel' subtitle='overzicht' />
			<div className={styles.center}>
				{overview.edges.map(({ node }) => {
					return <OverviewCard key={node.id} overview={node} />;
				})}
			</div>
		</section>
	);
};

export default OverviewList;
